<template>
  <el-upload
      class="upload-demo"
      ref="upload"
      :action="action"
      :accept="accept"
      :headers="headers"
      :on-success="success"
      :on-error="error"
      :before-upload="before_upload"
      :on-preview="handlePreview"
      :on-remove="handleRemove"
      :before-remove="beforeRemove"
      multiple
      :limit="1"
      :on-exceed="handleExceed"
      :file-list="fileList">
    <el-button size="small" type="primary" :loading="loading" >点击上传</el-button>
    <div slot="tip" class="el-upload__tip">{{ `文件格式为mp4,大小不超过${size_data}M` }}</div>
  </el-upload>
</template>

<script>
export default {
  name: "index",
  props: {
    value: {
      type: String,
      default: () => ""
    },
    showData: {
      type: String,
      default: () => ""
    }
  },
  data() {
    return {
      action: `${process.env.VUE_APP_APIURL}ec/uploadeducationcontentvideo`,
      accept: ".mp4",
      headers: {
        authorization: this.$store.state.token,
      },
      fileList: [],
      size_data: 300,
      loading: false,
    }
  },
  watch: {
    showData: {
      handler(newVal) {
        if (newVal) {
          this.fileList = [
            {
              name: this.getFileName(newVal),
              url: newVal,
            }
          ]
        }
      },
      immediate: true,
    },
  },
  computed: {
    size() {
      return this.size_data*1024*1024;
    },
    filePath: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input",value)
      }
    }
  },
  created() {

  },
  mounted() {

  },
  methods: {
    before_upload(file) {
      console.log(file);
      const isType = file.type === "video/mp4";
      const isLt = file.size < this.size;

      if (!isType) {
        this.$message({
          message: '文件上传格式不正确！',
          type: 'error'
        })
      }

      if (!isLt) {
        this.$message({
          message: `上传文件不能大于${this.size_data}M！`,
          type: 'error'
        })
      }

      if (isType && isLt) {
        this.loading = true;
      }

      return isType && isLt;
    },
    // eslint-disable-next-line no-unused-vars
    success(res, file, fileList) {
      this.filePath = res.data;
      this.loading = false;
    },
    error(error) {
      console.log(error);
    },
    handleExceed() {

    },
    handlePreview() {

    },
    handleRemove(file) {
      console.log(file)
      if (file.response) {
        this.delFn(file.response.data);
      }else if (!file.raw) {
        this.delFn(file.url);
      }
      this.$refs["upload"].clearFiles();
      this.loading = false;
    },
    delFn(fileUrl) {
      this.$get("ec/deleducationcontent",{
        fileUrl
      })
          // eslint-disable-next-line no-unused-vars
          .then(res => {
            this.filePath = "";
          })
    },
    beforeRemove() {

    },
    getFileName(url) {
      let file = url.split("/");
      return file[file.length - 1];
    },
  }
}
</script>

<style lang="scss" scoped>

</style>
